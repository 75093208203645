.toast_container {
    z-index: 50;
    display: flex;
    flex: 1;
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.message {
    background: var(--secondary-color);
    text-align: center;
    border-radius: 4px;
    font-size: 1rem;
    text-transform: uppercase;
    height: 30px;
    line-height: 30px;
    margin-bottom: .75rem;
    font-weight: 600;
    letter-spacing: .1rem;
    padding: 0 10px;
    border: 1px solid #8a898969;
}