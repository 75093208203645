:root {
  --primary-color: #f2b82d;
  --secondary-color: #ffdf9e;
  --font-color: #424242;
  --bg-color: #fff;
  --heading-color: #292922;
  --border-color: #a0a0a0;
  --header-font-color: #DAA527;
  --header-bg-color: #525252;
}

[data-theme="dark"] {
  --primary-color: #8c2d2d;
  --secondary-color: #775858;
  --font-color: #e3e3e3;
  --bg-color: #333333;
  --heading-color: #818cab;
  --border-color: #111111;
  --header-font-color: #DAA527;
  --header-bg-color: #525252;
}


.breadcrumb ul {
    list-style: none;
    display: flex;
}

body {
  padding-bottom: 100px;
  background: var(--bg-color);
  color: var(--font-color);
}

.row {
  padding: 0 10px;
}

.row:last-child {
  margin-bottom: 20px;
}


.collapsible-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background: var(--header-bg-color);
  padding: 0 12px;
  font: 11px;
  text-transform: uppercase;
  color: var(--header-font-color);
  height: 34px;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  font-weight: bold;
  position: sticky;
  top: 103px;
  z-index: 5;
}

.row_padded {
  margin-bottom: 10px;
}

.affix_type_header {
  background: var(--bg-color);
  color: var(--font-color);
  text-transform: uppercase;
  z-index: 4;
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 5px;
}

.affix_type_header.sticky {
  position: sticky;
  top: 136px;
}
/*
.container {
  padding: 0;
  margin: 0;
}
*/
.collapsible-header .row {
  margin: 0;
  width: 100%;
  font-weight: lighter;
  border-top: 1px solid var(--bg-color);
}

.mod_row {
    display: flex;
    flex: 1;
    flex-direction: column;
    cursor: pointer;
    font-family: Helvetica;
}

.hovered {
  background: var(--secondary-color)
}

.selected {
  background: var(--secondary-color) !important
}

.ilvl {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-right: 10px;
  background: var(--secondary-color);
  margin-bottom: 2px;
  min-width: 28px;
  min-height: 28px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  align-self: flex-start;
}


.ilvl_max_tier {
  background: var(--primary-color);
}

.slider_container {
  background: var(--bg-color);
  padding: 5px;
  z-index: 1;
  display: flex;
  flex: 1;
  align-items: center;
}


.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  background: #4f4f4f; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  margin: 0;
  height: 6px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: gold; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: gold; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.header {
  position: fixed;
  background: var(--bg-color);
}

div.disabled {
  opacity: 0.3;
}

.mod-tier-container {
  display: flex;
  margin-top: 4px;
  flex-direction: column;
}
.mod-tier-left-line {
  border-left: 2px solid var(--primary-color);
  padding-right: 5px;
  cursor: pointer;
}

.mod-tier-left-line:hover {
  border-left: 2px solid var(--secondary-color);
}

.mod_tiers_group {
  background: var(--bg-color);
  box-sizing: border-box;
  min-width: 45%;
  z-index: 1;
  box-shadow: 2px 1px 5px -2px #0000006b;
  flex: 1;
}

.collapsed {
  display: none;
}

.tooltip {
  display:inline-block;
  position:relative;
  text-align:left;
  opacity: 1;
  max-height: 28px;
}

.tooltip .bottom {
  top: 0;
  left: 108%;
  padding: 5px 10px;
  color: #e2e2e2;
  background-color: #514a4a;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0,0,0,0.5);
  display: none;
}

.tooltip:hover .bottom {
  display:block;
  white-space: nowrap;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 60px;
  overflow: visible;
}

.menu button {
  width: 170px;
}

.search-icon {
  width: 25px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary-color);
  height: 25px;
  margin: 7px;
}


.menu img {
  max-height: 58px;
  max-width: 35px;
  display: flex;
}

.collapsed {
  display: none;
}

nav.menu {
  margin: 0 auto;
  background:var(--bg-color);
  position: relative;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.12);
  min-height: 60px;
  position: sticky;
  top: 0;
  z-index: 10
}

.item_category {
  color: #b5abab;
  margin: 0;
  min-height: 60px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.category_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.tags-container input[type="checkbox"]:checked + label {
  background: var(--primary-color);
  color: var(--font-color)
}

.tags-container input[type="checkbox"] {
  position: absolute;
  visibility: hidden;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 1;
}

.tags-container label {
  background: var(--secondary-color);
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  user-select: none;
  
  padding: 5px 10px;
  margin: 3px;
  border-radius: 5px;
}

.tags-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
}

.f {
  display: flex;
}

.f1 {
  display: flex;
  flex: 1;
}

.fc1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hidden {
  visibility: hidden;
}

.main_wrapper {
  width: 100%;
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 35vh;
}


@media (min-width: 750px) {
  .main_wrapper {
    width: 85%;
  }
}

.clickable {
  cursor: pointer;
}

.base_item_name {
  flex: 1
}

.toolbar-position {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  transform: translateY(100%);
  transition: transform 0.5s ease;
}

.toolbar-position.active {
  transform: translateY(0);
}

.toolbar-container {
  background: var(--bg-color);
  border-top: 5px solid var(--primary-color);
  padding: 10px;
  display: flex;
  text-align: center;
  flex-direction: column;
  max-height: 35vh;
  overflow: scroll;
}
.toolbar-setting {
  display: flex;
  align-items: flex-start;
}

.toolbar-setting > span {
  flex: 1;
  text-align: left;
}

.toolbar-icon {
  width: 50px;
  height: 50px;
  padding: 2px;
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary-color);
  margin: 7px;
  font-family: helvetica;
  display: flex;
  align-items: center;
  color: #333;
}

.toolbar-icon > div {
  flex: 1;
}

.dark-mode-icon {
  background: #ddd;
}

.placeholder {
  display: flex;
  min-height: 1px;
  min-width: 1px;
}

.spaced_row {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.spaced_row div {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  user-select: none;
  padding: 5px 10px;
  margin: 3px;
  border-radius: 5px;
}

.spaced_row div:hover {
  background: var(--secondary-color);
}

.spaced_row div.highlight {
  background: var(--primary-color);
}

img.disabled {
  filter: grayscale(100%) opacity(0.5);
}
