/* glue: 0.13 hash: 2b6e4c1b16 */
        .sprite-scepter1,
.sprite-Wand7,
.sprite-Wand6,
.sprite-Wand5,
.sprite-Wand4,
.sprite-Wand3,
.sprite-Wand2,
.sprite-Wand1,
.sprite-TwoHandSword8,
.sprite-TwoHandSword7,
.sprite-TwoHandSword6,
.sprite-TwoHandSword5,
.sprite-TwoHandSword4,
.sprite-TwoHandSword3,
.sprite-TwoHandSword2,
.sprite-TwoHandSword1,
.sprite-TwoHandMace8,
.sprite-TwoHandMace7,
.sprite-TwoHandMace6,
.sprite-TwoHandMace5,
.sprite-TwoHandMace4,
.sprite-TwoHandMace3,
.sprite-TwoHandMace2,
.sprite-TwoHandMace1,
.sprite-TwoHandAxe8,
.sprite-TwoHandAxe7,
.sprite-TwoHandAxe6,
.sprite-TwoHandAxe5,
.sprite-TwoHandAxe4,
.sprite-TwoHandAxe3,
.sprite-TwoHandAxe2,
.sprite-TwoHandAxe1,
.sprite-Staff8,
.sprite-Staff7,
.sprite-Staff6,
.sprite-Staff5,
.sprite-Staff4,
.sprite-Staff3,
.sprite-Staff2,
.sprite-Staff1,
.sprite-ShieldStr7,
.sprite-ShieldStr6,
.sprite-ShieldStr5,
.sprite-ShieldStr4,
.sprite-ShieldStr3,
.sprite-ShieldStr2,
.sprite-ShieldStr1,
.sprite-Rapier9,
.sprite-Rapier8,
.sprite-Rapier7,
.sprite-Rapier6,
.sprite-Rapier5,
.sprite-Rapier4,
.sprite-Rapier3,
.sprite-Rapier2,
.sprite-Rapier1,
.sprite-OneHandSword3,
.sprite-OneHandSword2,
.sprite-OneHandSword1,
.sprite-OneHandMace3,
.sprite-OneHandMace2,
.sprite-OneHandMace1,
.sprite-OneHandAxe1,
.sprite-Dagger9,
.sprite-Dagger8,
.sprite-Dagger7,
.sprite-Dagger6,
.sprite-Dagger5,
.sprite-Dagger4,
.sprite-Dagger3,
.sprite-Dagger2,
.sprite-Dagger1,
.sprite-ConvokingWand,
.sprite-Bow9,
.sprite-Bow8,
.sprite-Bow7,
.sprite-Bow6,
.sprite-Bow5,
.sprite-Bow4,
.sprite-Bow3,
.sprite-scepter9,
.sprite-scepter8,
.sprite-scepter7,
.sprite-scepter6,
.sprite-scepter5,
.sprite-scepter4,
.sprite-scepter3,
.sprite-scepter2,
.sprite-TriggerQuiverUnique,
.sprite-TheGoddessUnleashed,
.sprite-ShieldStrInt5,
.sprite-ShieldStrInt4,
.sprite-ShieldStrInt3,
.sprite-ShieldStrInt2,
.sprite-ShieldStrInt1,
.sprite-ShieldStrDex5,
.sprite-ShieldStrDex4,
.sprite-ShieldStrDex3,
.sprite-ShieldStrDex2,
.sprite-ShieldStrDex1,
.sprite-SacrificialGarb,
.sprite-QuiverTwo-Point,
.sprite-QuiverSpike-Point,
.sprite-QuiverSharktooth,
.sprite-QuiverSerrated,
.sprite-QuiverPenetrating,
.sprite-QuiverFire,
.sprite-QuiverBroadhead,
.sprite-QuiverBlunt,
.sprite-Quiver5,
.sprite-Quiver4,
.sprite-Quiver3,
.sprite-Quiver2,
.sprite-Quiver1,
.sprite-OneHandSword9,
.sprite-OneHandSword8,
.sprite-OneHandSword7,
.sprite-OneHandSword6,
.sprite-OneHandSword5,
.sprite-OneHandSword4,
.sprite-OneHandMace9,
.sprite-OneHandMace8,
.sprite-OneHandMace7,
.sprite-OneHandMace6,
.sprite-OneHandMace5,
.sprite-OneHandMace4,
.sprite-OneHandAxe9,
.sprite-OneHandAxe8,
.sprite-OneHandAxe7,
.sprite-OneHandAxe6,
.sprite-OneHandAxe5,
.sprite-OneHandAxe4,
.sprite-OneHandAxe3,
.sprite-OneHandAxe2,
.sprite-DemigodsShield,
.sprite-DemigodsArmour,
.sprite-Bow2,
.sprite-Bow1,
.sprite-BodyStrInt3C,
.sprite-BodyStrInt2C,
.sprite-BodyStrInt2B,
.sprite-BodyStrInt2A,
.sprite-BodyStrInt1C,
.sprite-BodyStrInt1B,
.sprite-BodyStrInt1A,
.sprite-BodyStrDexInt1C,
.sprite-BodyStrDex2C,
.sprite-BodyStrDex2B,
.sprite-BodyStrDex2A,
.sprite-BodyStrDex1C,
.sprite-BodyStrDex1B,
.sprite-BodyStrDex1A,
.sprite-BodyStr4A,
.sprite-BodyStr3CUnique,
.sprite-BodyStr3C,
.sprite-BodyStr2C,
.sprite-BodyStr2B,
.sprite-BodyStr2A,
.sprite-BodyStr1C,
.sprite-BodyStr1B,
.sprite-BodyStr1A,
.sprite-BodyInt2C,
.sprite-BodyInt2B,
.sprite-BodyInt2A,
.sprite-BodyInt1C,
.sprite-BodyInt1B,
.sprite-BodyInt1A,
.sprite-BodyDexInt4C,
.sprite-BodyDexInt2C,
.sprite-BodyDexInt2B,
.sprite-BodyDexInt2A,
.sprite-BodyDexInt1C,
.sprite-BodyDexInt1B,
.sprite-BodyDexInt1A,
.sprite-BodyDex3C,
.sprite-BodyDex3B,
.sprite-BodyDex3A,
.sprite-BodyDex2C,
.sprite-BodyDex1C,
.sprite-BodyDex1B,
.sprite-BodyDex1A,
.sprite-basicstr,
.sprite-basicint,
.sprite-basicdex,
.sprite-Zeal7,
.sprite-Zeal6,
.sprite-Zeal5,
.sprite-Zeal4,
.sprite-Wrath7,
.sprite-Wrath6,
.sprite-Wrath5,
.sprite-Wrath4,
.sprite-Wrath3,
.sprite-Woe7,
.sprite-Woe6,
.sprite-Woe5,
.sprite-Woe4,
.sprite-Woe3,
.sprite-Woe2,
.sprite-Woe1,
.sprite-VioletOil,
.sprite-VialTemperedFlesh,
.sprite-VialStoryVaal,
.sprite-VialSpiritDrinker,
.sprite-VialSoulCatcher,
.sprite-VialSlumber,
.sprite-VialSacrificialHeart,
.sprite-VialDanceOffered,
.sprite-VialCowardsChains,
.sprite-VialArchitectsHand,
.sprite-VermillionRing,
.sprite-VerdantOil,
.sprite-VaalShard,
.sprite-TwoTonedBoots3B,
.sprite-TwoTonedBoots2B,
.sprite-TwoTonedBoots,
.sprite-TurquoiseAmulet,
.sprite-TurbulentCatalyst,
.sprite-TransferOrb,
.sprite-Torment7,
.sprite-Torment6,
.sprite-Torment5,
.sprite-Torment4,
.sprite-Torment3,
.sprite-Torment2,
.sprite-TopazSapphire,
.sprite-TopazRuby,
.sprite-Timeless,
.sprite-Terror1,
.sprite-TemplarShard,
.sprite-TemperingCatalyst,
.sprite-TealOil,
.sprite-TangledFossil,
.sprite-TalismanoftheVictor,
.sprite-TalismanWhite8,
.sprite-TalismanWhite7,
.sprite-TalismanWhite6,
.sprite-TalismanWhite5,
.sprite-TalismanWhite4,
.sprite-TalismanWhite3,
.sprite-TalismanWhite2,
.sprite-TalismanWhite1,
.sprite-TalismanRed8,
.sprite-TalismanRed7,
.sprite-TalismanRed6,
.sprite-TalismanRed4,
.sprite-TalismanRed3,
.sprite-TalismanRed1,
.sprite-TalismanBlack7,
.sprite-TalismanBlack6,
.sprite-TalismanBlack5,
.sprite-TalismanBlack4,
.sprite-TalismanBlack3,
.sprite-TalismanBlack2,
.sprite-T4Talisman,
.sprite-Suffering7,
.sprite-Suffering6,
.sprite-Suffering5,
.sprite-Suffering4,
.sprite-Suffering3,
.sprite-Spite7,
.sprite-Spite6,
.sprite-Spite5,
.sprite-Spite4,
.sprite-SpikedGloves,
.sprite-Sorrow7,
.sprite-Sorrow6,
.sprite-Sorrow5,
.sprite-Sorrow4,
.sprite-Sorrow3,
.sprite-Sorrow2,
.sprite-SilverOil,
.sprite-SilverObol,
.sprite-ShudderingFossil,
.sprite-ShieldInt6,
.sprite-ShieldInt5,
.sprite-ShieldInt4,
.sprite-ShieldInt3,
.sprite-ShieldInt2,
.sprite-ShieldInt1,
.sprite-ShieldDexInt5,
.sprite-ShieldDexInt4,
.sprite-ShieldDexInt3,
.sprite-ShieldDexInt2,
.sprite-ShieldDexInt1,
.sprite-ShieldDex6,
.sprite-ShieldDex5,
.sprite-ShieldDex4,
.sprite-ShieldDex3,
.sprite-ShieldDex2,
.sprite-ShieldDex1,
.sprite-SerratedFossil,
.sprite-SepiaOil,
.sprite-SearchingEye,
.sprite-SealYellow,
.sprite-SealWhite,
.sprite-SealRed,
.sprite-Scorn7,
.sprite-Scorn6,
.sprite-Scorn5,
.sprite-SapphireRuby,
.sprite-SanctifiedFossil,
.sprite-RubyAmulet,
.sprite-RivetedEye,
.sprite-RingoftheVictor,
.sprite-Ring7,
.sprite-Ring6,
.sprite-Ring5,
.sprite-Ring4,
.sprite-Ring3,
.sprite-Ring2,
.sprite-Ring12,
.sprite-Ring1,
.sprite-ResonatingFossil,
.sprite-RegalShard,
.sprite-Rage7,
.sprite-Rage6,
.sprite-Rage5,
.sprite-Rage4,
.sprite-Rage3,
.sprite-ProphecyOrbRed,
.sprite-PristineFossil,
.sprite-PrismaticFossil,
.sprite-PrismaticCatalyst,
.sprite-PerfectFossil,
.sprite-OpalescentOil,
.sprite-OpalRing,
.sprite-NewTalisman7,
.sprite-NewTalisman3,
.sprite-NewTalisman2,
.sprite-NewTalisman,
.sprite-NewGemBase3,
.sprite-NewGemBase2,
.sprite-NewGemBase1,
.sprite-Necronet,
.sprite-MurderousEye,
.sprite-MoonstoneRing,
.sprite-MonkeyPawTalisman,
.sprite-Misery7,
.sprite-Misery6,
.sprite-Misery5,
.sprite-MirrorShard,
.sprite-MetallicFossil,
.sprite-Mastery,
.sprite-MarbleAmulet,
.sprite-MarakethShard,
.sprite-Madness1,
.sprite-LucentFossil,
.sprite-Loathing7,
.sprite-Loathing6,
.sprite-Loathing5,
.sprite-Loathing4,
.sprite-KaruiShard,
.sprite-JaggedFossil,
.sprite-IntrinsicCatalyst,
.sprite-Insanity1,
.sprite-IncursionItemCorruption,
.sprite-IncursionGemCorruption,
.sprite-ImbuedCatalyst,
.sprite-Horror1,
.sprite-HorizonShard,
.sprite-HorizonOrb,
.sprite-HelmetStrInt9,
.sprite-HelmetStrInt8,
.sprite-HelmetStrInt7,
.sprite-HelmetStrInt6,
.sprite-HelmetStrInt5,
.sprite-HelmetStrInt4,
.sprite-HelmetStrInt3,
.sprite-HelmetStrInt2,
.sprite-HelmetStrInt10,
.sprite-HelmetStrInt1,
.sprite-HelmetStrDex9,
.sprite-HelmetStrDex8,
.sprite-HelmetStrDex7,
.sprite-HelmetStrDex6,
.sprite-HelmetStrDex5,
.sprite-HelmetStrDex4,
.sprite-HelmetStrDex3,
.sprite-HelmetStrDex2,
.sprite-HelmetStrDex10,
.sprite-HelmetStrDex1,
.sprite-HelmetStr9,
.sprite-HelmetStr8,
.sprite-HelmetStr7,
.sprite-HelmetStr6,
.sprite-HelmetStr5,
.sprite-HelmetStr4,
.sprite-HelmetStr3,
.sprite-HelmetStr2,
.sprite-HelmetStr11,
.sprite-HelmetStr10,
.sprite-HelmetStr1,
.sprite-HelmetInt9,
.sprite-HelmetInt8,
.sprite-HelmetInt7,
.sprite-HelmetInt6,
.sprite-HelmetInt5,
.sprite-HelmetInt4,
.sprite-HelmetInt3,
.sprite-HelmetInt2,
.sprite-HelmetInt11,
.sprite-HelmetInt10,
.sprite-HelmetInt1,
.sprite-HelmetDexInt9,
.sprite-HelmetDexInt8,
.sprite-HelmetDexInt7,
.sprite-HelmetDexInt6,
.sprite-HelmetDexInt5,
.sprite-HelmetDexInt4,
.sprite-HelmetDexInt3,
.sprite-HelmetDexInt2,
.sprite-HelmetDexInt11,
.sprite-HelmetDexInt10,
.sprite-HelmetDexInt1,
.sprite-HelmetDex9,
.sprite-HelmetDex8,
.sprite-HelmetDex7,
.sprite-HelmetDex6,
.sprite-HelmetDex5,
.sprite-HelmetDex4,
.sprite-HelmetDex3,
.sprite-HelmetDex2,
.sprite-HelmetDex10,
.sprite-HelmetDex1,
.sprite-Hatred7,
.sprite-Hatred6,
.sprite-Hatred5,
.sprite-Hatred4,
.sprite-Hatred3,
.sprite-Hatred2,
.sprite-Hatred1,
.sprite-HarbingerShard,
.sprite-HarbingerOrb,
.sprite-GripGlovesBaseType,
.sprite-Greed7,
.sprite-Greed6,
.sprite-Greed5,
.sprite-Greed4,
.sprite-Greed3,
.sprite-Greed2,
.sprite-Greed1,
.sprite-GoldenOil,
.sprite-GlyphicFossil,
.sprite-GlovesStrInt3,
.sprite-GlovesStrInt2,
.sprite-GlovesStrInt1,
.sprite-GlovesStrDex3,
.sprite-GlovesStrDex2,
.sprite-GlovesStrDex1,
.sprite-GlovesStr4,
.sprite-GlovesStr3,
.sprite-GlovesStr2,
.sprite-GlovesStr1,
.sprite-GlovesInt4,
.sprite-GlovesInt3,
.sprite-GlovesInt2,
.sprite-GlovesInt1,
.sprite-GlovesDexInt3,
.sprite-GlovesDexInt2,
.sprite-GlovesDexInt1,
.sprite-GlovesDex4,
.sprite-GlovesDex3,
.sprite-GlovesDex2,
.sprite-GlovesDex1,
.sprite-GildedFossil,
.sprite-GhastlyEye,
.sprite-FracturedFossil,
.sprite-FishTalisman,
.sprite-FingerlessSilkGloves,
.sprite-FertileCatalyst,
.sprite-Fear7,
.sprite-Fear6,
.sprite-Fear5,
.sprite-Fear4,
.sprite-Fear3,
.sprite-Fear2,
.sprite-FacetedFossil,
.sprite-ExaltedShard,
.sprite-EternalEmpireShard,
.sprite-EssenceCorrupt,
.sprite-Envy7,
.sprite-Envy6,
.sprite-Envy5,
.sprite-EngineersShard,
.sprite-EngineersOrb,
.sprite-EncrustedFossil,
.sprite-Enchantment,
.sprite-EnchantedFossil,
.sprite-Empty-Socket,
.sprite-Dread7,
.sprite-Dread6,
.sprite-Dread5,
.sprite-Doubt7,
.sprite-Doubt6,
.sprite-Doubt5,
.sprite-Doubt4,
.sprite-Doubt3,
.sprite-DireClawTalisman,
.sprite-DiamondRing,
.sprite-DenseFossil,
.sprite-Demigodstriumph,
.sprite-DemigodsTouch,
.sprite-DemigodsStride,
.sprite-DemigodsImmortality,
.sprite-DemigodsBand,
.sprite-Demibelt1,
.sprite-DeliriumSplinter,
.sprite-DeliriumOrbWeapons,
.sprite-DeliriumOrbUniques,
.sprite-DeliriumOrbTrinkets,
.sprite-DeliriumOrbTalismans,
.sprite-DeliriumOrbScarabs,
.sprite-DeliriumOrbProphecies,
.sprite-DeliriumOrbPerandus,
.sprite-DeliriumOrbMetamorph,
.sprite-DeliriumOrbMaps,
.sprite-DeliriumOrbLegion,
.sprite-DeliriumOrbLabyrinth,
.sprite-DeliriumOrbHarbinger,
.sprite-DeliriumOrbGeneric,
.sprite-DeliriumOrbGems,
.sprite-DeliriumOrbFragments,
.sprite-DeliriumOrbFossils,
.sprite-DeliriumOrbEssence,
.sprite-DeliriumOrbDivinationCards,
.sprite-DeliriumOrbCurrency,
.sprite-DeliriumOrbBreach,
.sprite-DeliriumOrbBlight,
.sprite-DeliriumOrbArmour,
.sprite-DeliriumOrbAbyss,
.sprite-Deck,
.sprite-CurrencyWeaponQuality,
.sprite-CurrencyVaal,
.sprite-CurrencyUpgradeToRareShard,
.sprite-CurrencyUpgradeToRare,
.sprite-CurrencyUpgradeToMagicShard,
.sprite-CurrencyUpgradeToMagic,
.sprite-CurrencyUpgradeRandomly,
.sprite-CurrencyUpgradeMagicToRare,
.sprite-CurrencyRhoaFeather,
.sprite-CurrencyRerollSocketNumbers,
.sprite-CurrencyRerollSocketLinks,
.sprite-CurrencyRerollSocketColours,
.sprite-CurrencyRerollRare,
.sprite-CurrencyRerollMagicShard,
.sprite-CurrencyRerollMagic,
.sprite-CurrencyPortal,
.sprite-CurrencyPassiveSkillRefund,
.sprite-CurrencyModValues,
.sprite-CurrencyMapQuality,
.sprite-CurrencyImprintOrb,
.sprite-CurrencyImprint,
.sprite-CurrencyImplicitMod,
.sprite-CurrencyIdentificationFragment,
.sprite-CurrencyIdentification,
.sprite-CurrencyGemQuality,
.sprite-CurrencyFlaskQuality,
.sprite-CurrencyDuplicate,
.sprite-CurrencyConvertToNormal,
.sprite-CurrencyCoin,
.sprite-CurrencyArmourQuality,
.sprite-CurrencyAddModToRare,
.sprite-CurrencyAddModToMagic,
.sprite-CrystalBelt,
.sprite-CrimsonOil,
.sprite-CorrodedFossil,
.sprite-Contempt7,
.sprite-Contempt6,
.sprite-Contempt5,
.sprite-Contempt4,
.sprite-Contempt3,
.sprite-Contempt2,
.sprite-Contempt1,
.sprite-ClearOil,
.sprite-Claw9,
.sprite-Claw8,
.sprite-Claw7,
.sprite-Claw6,
.sprite-Claw5,
.sprite-Claw4,
.sprite-Claw3,
.sprite-Claw2,
.sprite-Claw1,
.sprite-CitrineAmulet,
.sprite-ChaosShard,
.sprite-CeruleanRing,
.sprite-BreachUpgraderPhysical,
.sprite-BreachUpgraderLightning,
.sprite-BreachUpgraderFire,
.sprite-BreachUpgraderCold,
.sprite-BreachUpgraderChaos,
.sprite-BreachShardPhysical,
.sprite-BreachShardLightning,
.sprite-BreachShardFire,
.sprite-BreachShardCold,
.sprite-BreachShardChaos,
.sprite-BreachRing,
.sprite-BootsStrInt3,
.sprite-BootsStrInt2,
.sprite-BootsStrInt1,
.sprite-BootsStrDex3,
.sprite-BootsStrDex2,
.sprite-BootsStrDex1,
.sprite-BootsStr4,
.sprite-BootsStr3,
.sprite-BootsStr2,
.sprite-BootsStr1,
.sprite-BootsInt4,
.sprite-BootsInt3,
.sprite-BootsInt2,
.sprite-BootsInt1,
.sprite-BootsDexInt4,
.sprite-BootsDexInt3,
.sprite-BootsDexInt2,
.sprite-BootsDexInt1,
.sprite-BootsDex4,
.sprite-BootsDex3,
.sprite-BootsDex2,
.sprite-BootsDex1,
.sprite-BoneHelm,
.sprite-BluePearlAmulet,
.sprite-BloodstainedFossil,
.sprite-BlackOil,
.sprite-BindingShard,
.sprite-BindingOrb,
.sprite-BestiaryTrap9,
.sprite-BestiaryTrap8,
.sprite-BestiaryTrap7,
.sprite-BestiaryTrap6,
.sprite-BestiaryTrap5,
.sprite-BestiaryTrap4,
.sprite-BestiaryTrap3,
.sprite-BestiaryTrap2,
.sprite-BestiaryTrap10,
.sprite-BestiaryTrap1,
.sprite-BestiaryOrbFull,
.sprite-BestiaryOrbEmpty,
.sprite-Belt7,
.sprite-Belt6,
.sprite-Belt5,
.sprite-Belt4,
.sprite-Belt3,
.sprite-Belt2,
.sprite-Belt1,
.sprite-AzureOil,
.sprite-AtlasRadiusYellow,
.sprite-AtlasRadiusWhite,
.sprite-AtlasRadiusTier3,
.sprite-AtlasRadiusTier2,
.sprite-AtlasRadiusTier1,
.sprite-AtlasRadiusRed,
.sprite-AtlasDowngrade,
.sprite-AnnullShard,
.sprite-AnnullOrb,
.sprite-Anguish7,
.sprite-Anguish6,
.sprite-Anguish5,
.sprite-Anguish4,
.sprite-Anger7,
.sprite-Anger6,
.sprite-Anger5,
.sprite-Anger4,
.sprite-Anger3,
.sprite-Anger2,
.sprite-AncientShard,
.sprite-AncientOrb,
.sprite-Amulet7,
.sprite-Amulet6,
.sprite-Amulet5,
.sprite-Amulet4,
.sprite-Amulet3,
.sprite-Amulet2,
.sprite-Amulet1,
.sprite-AmethystRing,
.sprite-AmberOil,
.sprite-AmberFireFly,
.sprite-AmberFang,
.sprite-AmberEye,
.sprite-AgateAmulet,
.sprite-AethericFossil,
.sprite-AdamantineRing,
.sprite-AbyssBelt,
.sprite-AbrasiveCatalyst,
.sprite-AbberantFossil {
            background-image: url('poe-sprite-small.png');
            background-repeat: no-repeat;
        }
        
        .sprite-scepter1 {
            background-position: 0 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Wand7 {
            background-position: -18px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Wand6 {
            background-position: -36px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Wand5 {
            background-position: -54px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Wand4 {
            background-position: -72px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Wand3 {
            background-position: -90px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Wand2 {
            background-position: -108px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Wand1 {
            background-position: 0 -55px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-TwoHandSword8 {
            background-position: -18px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandSword7 {
            background-position: -46px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandSword6 {
            background-position: -74px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandSword5 {
            background-position: -126px 0;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandSword4 {
            background-position: -126px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandSword3 {
            background-position: -154px 0;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandSword2 {
            background-position: -154px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandSword1 {
            background-position: -102px -55px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-TwoHandMace8 {
            background-position: 0 -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandMace7 {
            background-position: -28px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandMace6 {
            background-position: -56px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandMace5 {
            background-position: -84px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandMace4 {
            background-position: -112px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandMace3 {
            background-position: -140px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandMace2 {
            background-position: -182px 0;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandMace1 {
            background-position: -182px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe8 {
            background-position: -182px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe7 {
            background-position: -210px 0;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe6 {
            background-position: -210px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe5 {
            background-position: -210px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe4 {
            background-position: 0 -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe3 {
            background-position: -28px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe2 {
            background-position: -56px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-TwoHandAxe1 {
            background-position: -84px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff8 {
            background-position: -112px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff7 {
            background-position: -140px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff6 {
            background-position: -168px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff5 {
            background-position: -196px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff4 {
            background-position: -238px 0;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff3 {
            background-position: -238px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff2 {
            background-position: -238px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Staff1 {
            background-position: -238px -165px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-ShieldStr7 {
            background-position: -266px 0;
            width: 28px;
            height: 55px;
        }
        
        .sprite-ShieldStr6 {
            background-position: -266px -55px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-ShieldStr5 {
            background-position: -266px -110px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-ShieldStr4 {
            background-position: -266px -165px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-ShieldStr3 {
            background-position: 0 -220px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-ShieldStr2 {
            background-position: -28px -220px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-ShieldStr1 {
            background-position: -56px -220px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Rapier9 {
            background-position: -252px -165px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier8 {
            background-position: -168px -110px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier7 {
            background-position: -224px -165px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier6 {
            background-position: -84px -220px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier5 {
            background-position: -98px -220px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier4 {
            background-position: -112px -220px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier3 {
            background-position: -126px -220px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier2 {
            background-position: -140px -220px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-Rapier1 {
            background-position: -154px -220px;
            width: 14px;
            height: 55px;
        }
        
        .sprite-OneHandSword3 {
            background-position: -168px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-OneHandSword2 {
            background-position: -186px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-OneHandSword1 {
            background-position: -204px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-OneHandMace3 {
            background-position: -222px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-OneHandMace2 {
            background-position: -240px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-OneHandMace1 {
            background-position: -258px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-OneHandAxe1 {
            background-position: -276px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger9 {
            background-position: -294px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger8 {
            background-position: -294px -55px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger7 {
            background-position: -294px -110px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger6 {
            background-position: -294px -165px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger5 {
            background-position: -294px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger4 {
            background-position: -312px 0;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger3 {
            background-position: -312px -55px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger2 {
            background-position: -312px -110px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Dagger1 {
            background-position: -312px -165px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-ConvokingWand {
            background-position: -312px -220px;
            width: 18px;
            height: 55px;
        }
        
        .sprite-Bow9 {
            background-position: 0 -275px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Bow8 {
            background-position: -28px -275px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Bow7 {
            background-position: -56px -275px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Bow6 {
            background-position: -84px -275px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Bow5 {
            background-position: -112px -275px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Bow4 {
            background-position: -140px -275px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-Bow3 {
            background-position: -168px -275px;
            width: 28px;
            height: 55px;
        }
        
        .sprite-scepter9 {
            background-position: -196px -275px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-scepter8 {
            background-position: -226px -275px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-scepter7 {
            background-position: -256px -275px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-scepter6 {
            background-position: -286px -275px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-scepter5 {
            background-position: -330px 0;
            width: 30px;
            height: 45px;
        }
        
        .sprite-scepter4 {
            background-position: -330px -45px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-scepter3 {
            background-position: -330px -90px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-scepter2 {
            background-position: -330px -135px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-TriggerQuiverUnique {
            background-position: -330px -180px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-TheGoddessUnleashed {
            background-position: -330px -225px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrInt5 {
            background-position: -330px -270px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrInt4 {
            background-position: -360px 0;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrInt3 {
            background-position: -360px -45px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrInt2 {
            background-position: -360px -90px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrInt1 {
            background-position: -360px -135px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrDex5 {
            background-position: -360px -180px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrDex4 {
            background-position: -360px -225px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrDex3 {
            background-position: -360px -270px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrDex2 {
            background-position: 0 -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-ShieldStrDex1 {
            background-position: -30px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-SacrificialGarb {
            background-position: -60px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverTwo-Point {
            background-position: -90px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverSpike-Point {
            background-position: -120px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverSharktooth {
            background-position: -150px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverSerrated {
            background-position: -180px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverPenetrating {
            background-position: -210px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverFire {
            background-position: -240px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverBroadhead {
            background-position: -270px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-QuiverBlunt {
            background-position: -300px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-Quiver5 {
            background-position: -330px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-Quiver4 {
            background-position: -360px -330px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-Quiver3 {
            background-position: -390px 0;
            width: 30px;
            height: 45px;
        }
        
        .sprite-Quiver2 {
            background-position: -390px -45px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-Quiver1 {
            background-position: -390px -90px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandSword9 {
            background-position: -390px -135px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandSword8 {
            background-position: -390px -180px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandSword7 {
            background-position: -390px -225px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandSword6 {
            background-position: -390px -270px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandSword5 {
            background-position: -390px -315px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandSword4 {
            background-position: 0 -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandMace9 {
            background-position: -30px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandMace8 {
            background-position: -60px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandMace7 {
            background-position: -90px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandMace6 {
            background-position: -120px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandMace5 {
            background-position: -150px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandMace4 {
            background-position: -180px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe9 {
            background-position: -210px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe8 {
            background-position: -240px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe7 {
            background-position: -270px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe6 {
            background-position: -300px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe5 {
            background-position: -330px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe4 {
            background-position: -360px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe3 {
            background-position: -390px -375px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-OneHandAxe2 {
            background-position: -420px 0;
            width: 30px;
            height: 45px;
        }
        
        .sprite-DemigodsShield {
            background-position: -420px -45px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-DemigodsArmour {
            background-position: -420px -90px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-Bow2 {
            background-position: -420px -135px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-Bow1 {
            background-position: -420px -180px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrInt3C {
            background-position: -420px -225px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrInt2C {
            background-position: -420px -270px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrInt2B {
            background-position: -420px -315px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrInt2A {
            background-position: -420px -360px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrInt1C {
            background-position: -450px 0;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrInt1B {
            background-position: -450px -45px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrInt1A {
            background-position: -450px -90px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrDexInt1C {
            background-position: -450px -135px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrDex2C {
            background-position: -450px -180px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrDex2B {
            background-position: -450px -225px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrDex2A {
            background-position: -450px -270px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrDex1C {
            background-position: -450px -315px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrDex1B {
            background-position: -450px -360px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStrDex1A {
            background-position: 0 -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr4A {
            background-position: -30px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr3CUnique {
            background-position: -60px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr3C {
            background-position: -90px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr2C {
            background-position: -120px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr2B {
            background-position: -150px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr2A {
            background-position: -180px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr1C {
            background-position: -210px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr1B {
            background-position: -240px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyStr1A {
            background-position: -270px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyInt2C {
            background-position: -300px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyInt2B {
            background-position: -330px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyInt2A {
            background-position: -360px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyInt1C {
            background-position: -390px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyInt1B {
            background-position: -420px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyInt1A {
            background-position: -450px -420px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDexInt4C {
            background-position: -480px 0;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDexInt2C {
            background-position: -480px -45px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDexInt2B {
            background-position: -480px -90px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDexInt2A {
            background-position: -480px -135px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDexInt1C {
            background-position: -480px -180px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDexInt1B {
            background-position: -480px -225px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDexInt1A {
            background-position: -480px -270px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDex3C {
            background-position: -480px -315px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDex3B {
            background-position: -480px -360px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDex3A {
            background-position: -480px -405px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDex2C {
            background-position: 0 -465px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDex1C {
            background-position: -30px -465px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDex1B {
            background-position: -60px -465px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-BodyDex1A {
            background-position: -90px -465px;
            width: 30px;
            height: 45px;
        }
        
        .sprite-basicstr {
            background-position: -120px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-basicint {
            background-position: -150px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-basicdex {
            background-position: -180px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Zeal7 {
            background-position: -210px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Zeal6 {
            background-position: -240px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Zeal5 {
            background-position: -270px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Zeal4 {
            background-position: -300px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Wrath7 {
            background-position: -330px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Wrath6 {
            background-position: -360px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Wrath5 {
            background-position: -390px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Wrath4 {
            background-position: -420px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Wrath3 {
            background-position: -450px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Woe7 {
            background-position: -480px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Woe6 {
            background-position: -510px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Woe5 {
            background-position: -510px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Woe4 {
            background-position: -510px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Woe3 {
            background-position: -510px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Woe2 {
            background-position: -510px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Woe1 {
            background-position: -510px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VioletOil {
            background-position: -510px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialTemperedFlesh {
            background-position: -510px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialStoryVaal {
            background-position: -510px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialSpiritDrinker {
            background-position: -510px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialSoulCatcher {
            background-position: -510px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialSlumber {
            background-position: -510px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialSacrificialHeart {
            background-position: -510px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialDanceOffered {
            background-position: -510px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialCowardsChains {
            background-position: -510px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VialArchitectsHand {
            background-position: -510px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VermillionRing {
            background-position: -510px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VerdantOil {
            background-position: 0 -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-VaalShard {
            background-position: -30px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TwoTonedBoots3B {
            background-position: -60px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TwoTonedBoots2B {
            background-position: -90px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TwoTonedBoots {
            background-position: -120px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TurquoiseAmulet {
            background-position: -150px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TurbulentCatalyst {
            background-position: -180px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TransferOrb {
            background-position: -210px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Torment7 {
            background-position: -240px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Torment6 {
            background-position: -270px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Torment5 {
            background-position: -300px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Torment4 {
            background-position: -330px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Torment3 {
            background-position: -360px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Torment2 {
            background-position: -390px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TopazSapphire {
            background-position: -420px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TopazRuby {
            background-position: -450px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Timeless {
            background-position: -480px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Terror1 {
            background-position: -510px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TemplarShard {
            background-position: -540px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TemperingCatalyst {
            background-position: -540px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TealOil {
            background-position: -540px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TangledFossil {
            background-position: -540px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanoftheVictor {
            background-position: -540px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite8 {
            background-position: -540px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite7 {
            background-position: -540px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite6 {
            background-position: -540px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite5 {
            background-position: -540px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite4 {
            background-position: -540px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite3 {
            background-position: -540px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite2 {
            background-position: -540px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanWhite1 {
            background-position: -540px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanRed8 {
            background-position: -540px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanRed7 {
            background-position: -540px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanRed6 {
            background-position: -540px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanRed4 {
            background-position: -540px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanRed3 {
            background-position: -540px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanRed1 {
            background-position: 0 -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanBlack7 {
            background-position: -30px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanBlack6 {
            background-position: -60px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanBlack5 {
            background-position: -90px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanBlack4 {
            background-position: -120px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanBlack3 {
            background-position: -150px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-TalismanBlack2 {
            background-position: -180px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-T4Talisman {
            background-position: -210px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Suffering7 {
            background-position: -240px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Suffering6 {
            background-position: -270px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Suffering5 {
            background-position: -300px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Suffering4 {
            background-position: -330px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Suffering3 {
            background-position: -360px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Spite7 {
            background-position: -390px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Spite6 {
            background-position: -420px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Spite5 {
            background-position: -450px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Spite4 {
            background-position: -480px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SpikedGloves {
            background-position: -510px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Sorrow7 {
            background-position: -540px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Sorrow6 {
            background-position: -570px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Sorrow5 {
            background-position: -570px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Sorrow4 {
            background-position: -570px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Sorrow3 {
            background-position: -570px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Sorrow2 {
            background-position: -570px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SilverOil {
            background-position: -570px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SilverObol {
            background-position: -570px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShudderingFossil {
            background-position: -570px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldInt6 {
            background-position: -570px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldInt5 {
            background-position: -570px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldInt4 {
            background-position: -570px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldInt3 {
            background-position: -570px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldInt2 {
            background-position: -570px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldInt1 {
            background-position: -570px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDexInt5 {
            background-position: -570px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDexInt4 {
            background-position: -570px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDexInt3 {
            background-position: -570px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDexInt2 {
            background-position: -570px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDexInt1 {
            background-position: -570px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDex6 {
            background-position: 0 -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDex5 {
            background-position: -30px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDex4 {
            background-position: -60px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDex3 {
            background-position: -90px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDex2 {
            background-position: -120px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ShieldDex1 {
            background-position: -150px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SerratedFossil {
            background-position: -180px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SepiaOil {
            background-position: -210px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SearchingEye {
            background-position: -240px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SealYellow {
            background-position: -270px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SealWhite {
            background-position: -300px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SealRed {
            background-position: -330px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Scorn7 {
            background-position: -360px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Scorn6 {
            background-position: -390px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Scorn5 {
            background-position: -420px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SapphireRuby {
            background-position: -450px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-SanctifiedFossil {
            background-position: -480px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-RubyAmulet {
            background-position: -510px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-RivetedEye {
            background-position: -540px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-RingoftheVictor {
            background-position: -570px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring7 {
            background-position: -600px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring6 {
            background-position: -600px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring5 {
            background-position: -600px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring4 {
            background-position: -600px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring3 {
            background-position: -600px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring2 {
            background-position: -600px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring12 {
            background-position: -600px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Ring1 {
            background-position: -600px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ResonatingFossil {
            background-position: -600px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-RegalShard {
            background-position: -600px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Rage7 {
            background-position: -600px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Rage6 {
            background-position: -600px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Rage5 {
            background-position: -600px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Rage4 {
            background-position: -600px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Rage3 {
            background-position: -600px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ProphecyOrbRed {
            background-position: -600px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-PristineFossil {
            background-position: -600px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-PrismaticFossil {
            background-position: -600px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-PrismaticCatalyst {
            background-position: -600px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-PerfectFossil {
            background-position: -600px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-OpalescentOil {
            background-position: 0 -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-OpalRing {
            background-position: -30px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-NewTalisman7 {
            background-position: -60px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-NewTalisman3 {
            background-position: -90px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-NewTalisman2 {
            background-position: -120px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-NewTalisman {
            background-position: -150px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-NewGemBase3 {
            background-position: -180px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-NewGemBase2 {
            background-position: -210px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-NewGemBase1 {
            background-position: -240px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Necronet {
            background-position: -270px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-MurderousEye {
            background-position: -300px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-MoonstoneRing {
            background-position: -330px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-MonkeyPawTalisman {
            background-position: -360px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Misery7 {
            background-position: -390px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Misery6 {
            background-position: -420px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Misery5 {
            background-position: -450px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-MirrorShard {
            background-position: -480px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-MetallicFossil {
            background-position: -510px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Mastery {
            background-position: -540px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-MarbleAmulet {
            background-position: -570px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-MarakethShard {
            background-position: -600px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Madness1 {
            background-position: -630px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-LucentFossil {
            background-position: -630px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Loathing7 {
            background-position: -630px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Loathing6 {
            background-position: -630px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Loathing5 {
            background-position: -630px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Loathing4 {
            background-position: -630px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-KaruiShard {
            background-position: -630px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-JaggedFossil {
            background-position: -630px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-IntrinsicCatalyst {
            background-position: -630px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Insanity1 {
            background-position: -630px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-IncursionItemCorruption {
            background-position: -630px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-IncursionGemCorruption {
            background-position: -630px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ImbuedCatalyst {
            background-position: -630px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Horror1 {
            background-position: -630px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HorizonShard {
            background-position: -630px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HorizonOrb {
            background-position: -630px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt9 {
            background-position: -630px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt8 {
            background-position: -630px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt7 {
            background-position: -630px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt6 {
            background-position: -630px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt5 {
            background-position: -630px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt4 {
            background-position: 0 -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt3 {
            background-position: -30px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt2 {
            background-position: -60px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt10 {
            background-position: -90px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrInt1 {
            background-position: -120px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex9 {
            background-position: -150px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex8 {
            background-position: -180px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex7 {
            background-position: -210px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex6 {
            background-position: -240px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex5 {
            background-position: -270px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex4 {
            background-position: -300px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex3 {
            background-position: -330px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex2 {
            background-position: -360px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex10 {
            background-position: -390px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStrDex1 {
            background-position: -420px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr9 {
            background-position: -450px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr8 {
            background-position: -480px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr7 {
            background-position: -510px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr6 {
            background-position: -540px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr5 {
            background-position: -570px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr4 {
            background-position: -600px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr3 {
            background-position: -630px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr2 {
            background-position: -660px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr11 {
            background-position: -660px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr10 {
            background-position: -660px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetStr1 {
            background-position: -660px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt9 {
            background-position: -660px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt8 {
            background-position: -660px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt7 {
            background-position: -660px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt6 {
            background-position: -660px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt5 {
            background-position: -660px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt4 {
            background-position: -660px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt3 {
            background-position: -660px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt2 {
            background-position: -660px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt11 {
            background-position: -660px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt10 {
            background-position: -660px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetInt1 {
            background-position: -660px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt9 {
            background-position: -660px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt8 {
            background-position: -660px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt7 {
            background-position: -660px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt6 {
            background-position: -660px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt5 {
            background-position: -660px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt4 {
            background-position: -660px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt3 {
            background-position: -660px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt2 {
            background-position: 0 -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt11 {
            background-position: -30px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt10 {
            background-position: -60px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDexInt1 {
            background-position: -90px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex9 {
            background-position: -120px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex8 {
            background-position: -150px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex7 {
            background-position: -180px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex6 {
            background-position: -210px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex5 {
            background-position: -240px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex4 {
            background-position: -270px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex3 {
            background-position: -300px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex2 {
            background-position: -330px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex10 {
            background-position: -360px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HelmetDex1 {
            background-position: -390px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Hatred7 {
            background-position: -420px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Hatred6 {
            background-position: -450px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Hatred5 {
            background-position: -480px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Hatred4 {
            background-position: -510px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Hatred3 {
            background-position: -540px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Hatred2 {
            background-position: -570px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Hatred1 {
            background-position: -600px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HarbingerShard {
            background-position: -630px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-HarbingerOrb {
            background-position: -660px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GripGlovesBaseType {
            background-position: -690px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Greed7 {
            background-position: -690px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Greed6 {
            background-position: -690px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Greed5 {
            background-position: -690px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Greed4 {
            background-position: -690px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Greed3 {
            background-position: -690px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Greed2 {
            background-position: -690px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Greed1 {
            background-position: -690px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GoldenOil {
            background-position: -690px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlyphicFossil {
            background-position: -690px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStrInt3 {
            background-position: -690px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStrInt2 {
            background-position: -690px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStrInt1 {
            background-position: -690px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStrDex3 {
            background-position: -690px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStrDex2 {
            background-position: -690px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStrDex1 {
            background-position: -690px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStr4 {
            background-position: -690px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStr3 {
            background-position: -690px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStr2 {
            background-position: -690px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesStr1 {
            background-position: -690px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesInt4 {
            background-position: -690px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesInt3 {
            background-position: -690px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesInt2 {
            background-position: -690px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesInt1 {
            background-position: 0 -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesDexInt3 {
            background-position: -30px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesDexInt2 {
            background-position: -60px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesDexInt1 {
            background-position: -90px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesDex4 {
            background-position: -120px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesDex3 {
            background-position: -150px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesDex2 {
            background-position: -180px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GlovesDex1 {
            background-position: -210px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GildedFossil {
            background-position: -240px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-GhastlyEye {
            background-position: -270px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-FracturedFossil {
            background-position: -300px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-FishTalisman {
            background-position: -330px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-FingerlessSilkGloves {
            background-position: -360px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-FertileCatalyst {
            background-position: -390px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Fear7 {
            background-position: -420px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Fear6 {
            background-position: -450px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Fear5 {
            background-position: -480px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Fear4 {
            background-position: -510px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Fear3 {
            background-position: -540px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Fear2 {
            background-position: -570px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-FacetedFossil {
            background-position: -600px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ExaltedShard {
            background-position: -630px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-EternalEmpireShard {
            background-position: -660px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-EssenceCorrupt {
            background-position: -690px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Envy7 {
            background-position: -720px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Envy6 {
            background-position: -720px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Envy5 {
            background-position: -720px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-EngineersShard {
            background-position: -720px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-EngineersOrb {
            background-position: -720px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-EncrustedFossil {
            background-position: -720px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Enchantment {
            background-position: -720px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-EnchantedFossil {
            background-position: -720px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Empty-Socket {
            background-position: -720px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Dread7 {
            background-position: -720px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Dread6 {
            background-position: -720px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Dread5 {
            background-position: -720px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Doubt7 {
            background-position: -720px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Doubt6 {
            background-position: -720px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Doubt5 {
            background-position: -720px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Doubt4 {
            background-position: -720px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Doubt3 {
            background-position: -720px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DireClawTalisman {
            background-position: -720px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DiamondRing {
            background-position: -720px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DenseFossil {
            background-position: -720px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Demigodstriumph {
            background-position: -720px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DemigodsTouch {
            background-position: -720px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DemigodsStride {
            background-position: -720px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DemigodsImmortality {
            background-position: -720px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DemigodsBand {
            background-position: 0 -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Demibelt1 {
            background-position: -480px -450px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-DeliriumSplinter {
            background-position: -30px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbWeapons {
            background-position: -60px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbUniques {
            background-position: -90px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbTrinkets {
            background-position: -120px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbTalismans {
            background-position: -150px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbScarabs {
            background-position: -180px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbProphecies {
            background-position: -210px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbPerandus {
            background-position: -240px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbMetamorph {
            background-position: -270px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbMaps {
            background-position: -300px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbLegion {
            background-position: -330px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbLabyrinth {
            background-position: -360px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbHarbinger {
            background-position: -390px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbGeneric {
            background-position: -420px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbGems {
            background-position: -450px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbFragments {
            background-position: -480px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbFossils {
            background-position: -510px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbEssence {
            background-position: -540px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbDivinationCards {
            background-position: -570px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbCurrency {
            background-position: -600px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbBreach {
            background-position: -630px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbBlight {
            background-position: -660px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbArmour {
            background-position: -690px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-DeliriumOrbAbyss {
            background-position: -720px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Deck {
            background-position: -750px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyWeaponQuality {
            background-position: -750px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyVaal {
            background-position: -750px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyUpgradeToRareShard {
            background-position: -750px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyUpgradeToRare {
            background-position: -750px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyUpgradeToMagicShard {
            background-position: -750px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyUpgradeToMagic {
            background-position: -750px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyUpgradeRandomly {
            background-position: -750px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyUpgradeMagicToRare {
            background-position: -750px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyRhoaFeather {
            background-position: -750px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyRerollSocketNumbers {
            background-position: -750px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyRerollSocketLinks {
            background-position: -750px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyRerollSocketColours {
            background-position: -750px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyRerollRare {
            background-position: -750px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyRerollMagicShard {
            background-position: -750px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyRerollMagic {
            background-position: -750px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyPortal {
            background-position: -750px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyPassiveSkillRefund {
            background-position: -750px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyModValues {
            background-position: -750px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyMapQuality {
            background-position: -750px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyImprintOrb {
            background-position: -750px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyImprint {
            background-position: -750px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyImplicitMod {
            background-position: -750px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyIdentificationFragment {
            background-position: -750px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyIdentification {
            background-position: -750px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyGemQuality {
            background-position: 0 -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyFlaskQuality {
            background-position: -30px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyDuplicate {
            background-position: -60px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyConvertToNormal {
            background-position: -90px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyCoin {
            background-position: -120px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyArmourQuality {
            background-position: -150px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyAddModToRare {
            background-position: -180px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CurrencyAddModToMagic {
            background-position: -210px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CrystalBelt {
            background-position: -450px -405px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-CrimsonOil {
            background-position: -240px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CorrodedFossil {
            background-position: -270px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Contempt7 {
            background-position: -300px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Contempt6 {
            background-position: -330px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Contempt5 {
            background-position: -360px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Contempt4 {
            background-position: -390px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Contempt3 {
            background-position: -420px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Contempt2 {
            background-position: -450px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Contempt1 {
            background-position: -480px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ClearOil {
            background-position: -510px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw9 {
            background-position: -540px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw8 {
            background-position: -570px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw7 {
            background-position: -600px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw6 {
            background-position: -630px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw5 {
            background-position: -660px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw4 {
            background-position: -690px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw3 {
            background-position: -720px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw2 {
            background-position: -750px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Claw1 {
            background-position: -780px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CitrineAmulet {
            background-position: -780px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-ChaosShard {
            background-position: -780px -60px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-CeruleanRing {
            background-position: -780px -90px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachUpgraderPhysical {
            background-position: -780px -120px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachUpgraderLightning {
            background-position: -780px -150px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachUpgraderFire {
            background-position: -780px -180px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachUpgraderCold {
            background-position: -780px -210px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachUpgraderChaos {
            background-position: -780px -240px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachShardPhysical {
            background-position: -780px -270px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachShardLightning {
            background-position: -780px -300px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachShardFire {
            background-position: -780px -330px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachShardCold {
            background-position: -780px -360px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachShardChaos {
            background-position: -780px -390px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BreachRing {
            background-position: -780px -420px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStrInt3 {
            background-position: -780px -450px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStrInt2 {
            background-position: -780px -480px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStrInt1 {
            background-position: -780px -510px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStrDex3 {
            background-position: -780px -540px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStrDex2 {
            background-position: -780px -570px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStrDex1 {
            background-position: -780px -600px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStr4 {
            background-position: -780px -630px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStr3 {
            background-position: -780px -660px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStr2 {
            background-position: -780px -690px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsStr1 {
            background-position: -780px -720px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsInt4 {
            background-position: -780px -750px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsInt3 {
            background-position: 0 -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsInt2 {
            background-position: -30px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsInt1 {
            background-position: -60px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDexInt4 {
            background-position: -90px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDexInt3 {
            background-position: -120px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDexInt2 {
            background-position: -150px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDexInt1 {
            background-position: -180px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDex4 {
            background-position: -210px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDex3 {
            background-position: -240px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDex2 {
            background-position: -270px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BootsDex1 {
            background-position: -300px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BoneHelm {
            background-position: -330px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BluePearlAmulet {
            background-position: -360px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BloodstainedFossil {
            background-position: -390px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BlackOil {
            background-position: -420px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BindingShard {
            background-position: -450px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BindingOrb {
            background-position: -480px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap9 {
            background-position: -510px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap8 {
            background-position: -540px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap7 {
            background-position: -570px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap6 {
            background-position: -600px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap5 {
            background-position: -630px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap4 {
            background-position: -660px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap3 {
            background-position: -690px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap2 {
            background-position: -720px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap10 {
            background-position: -750px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryTrap1 {
            background-position: -780px -780px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryOrbFull {
            background-position: -810px 0;
            width: 30px;
            height: 30px;
        }
        
        .sprite-BestiaryOrbEmpty {
            background-position: -810px -30px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Belt7 {
            background-position: -810px -60px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-Belt6 {
            background-position: -810px -75px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-Belt5 {
            background-position: -810px -90px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-Belt4 {
            background-position: -810px -105px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-Belt3 {
            background-position: -810px -120px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-Belt2 {
            background-position: -810px -135px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-Belt1 {
            background-position: -810px -150px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-AzureOil {
            background-position: -810px -165px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AtlasRadiusYellow {
            background-position: -810px -195px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AtlasRadiusWhite {
            background-position: -810px -225px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AtlasRadiusTier3 {
            background-position: -810px -255px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AtlasRadiusTier2 {
            background-position: -810px -285px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AtlasRadiusTier1 {
            background-position: -810px -315px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AtlasRadiusRed {
            background-position: -810px -345px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AtlasDowngrade {
            background-position: -810px -375px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AnnullShard {
            background-position: -810px -405px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AnnullOrb {
            background-position: -810px -435px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anguish7 {
            background-position: -810px -465px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anguish6 {
            background-position: -810px -495px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anguish5 {
            background-position: -810px -525px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anguish4 {
            background-position: -810px -555px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anger7 {
            background-position: -810px -585px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anger6 {
            background-position: -810px -615px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anger5 {
            background-position: -810px -645px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anger4 {
            background-position: -810px -675px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anger3 {
            background-position: -810px -705px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Anger2 {
            background-position: -810px -735px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AncientShard {
            background-position: -810px -765px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AncientOrb {
            background-position: 0 -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Amulet7 {
            background-position: -30px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Amulet6 {
            background-position: -60px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Amulet5 {
            background-position: -90px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Amulet4 {
            background-position: -120px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Amulet3 {
            background-position: -150px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Amulet2 {
            background-position: -180px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-Amulet1 {
            background-position: -210px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AmethystRing {
            background-position: -240px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AmberOil {
            background-position: -270px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AmberFireFly {
            background-position: -300px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AmberFang {
            background-position: -330px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AmberEye {
            background-position: -360px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AgateAmulet {
            background-position: -390px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AethericFossil {
            background-position: -420px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AdamantineRing {
            background-position: -450px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AbyssBelt {
            background-position: -810px -795px;
            width: 30px;
            height: 15px;
        }
        
        .sprite-AbrasiveCatalyst {
            background-position: -480px -810px;
            width: 30px;
            height: 30px;
        }
        
        .sprite-AbberantFossil {
            background-position: -510px -810px;
            width: 30px;
            height: 30px;
        }
        
        @media screen and (-webkit-min-device-pixel-ratio: 1.0), screen and (min--moz-device-pixel-ratio: 1.0), screen and (-o-min-device-pixel-ratio: 100/100), screen and (min-device-pixel-ratio: 1.0), screen and (min-resolution: 1.0dppx) {
            .sprite-scepter1,
    .sprite-Wand7,
    .sprite-Wand6,
    .sprite-Wand5,
    .sprite-Wand4,
    .sprite-Wand3,
    .sprite-Wand2,
    .sprite-Wand1,
    .sprite-TwoHandSword8,
    .sprite-TwoHandSword7,
    .sprite-TwoHandSword6,
    .sprite-TwoHandSword5,
    .sprite-TwoHandSword4,
    .sprite-TwoHandSword3,
    .sprite-TwoHandSword2,
    .sprite-TwoHandSword1,
    .sprite-TwoHandMace8,
    .sprite-TwoHandMace7,
    .sprite-TwoHandMace6,
    .sprite-TwoHandMace5,
    .sprite-TwoHandMace4,
    .sprite-TwoHandMace3,
    .sprite-TwoHandMace2,
    .sprite-TwoHandMace1,
    .sprite-TwoHandAxe8,
    .sprite-TwoHandAxe7,
    .sprite-TwoHandAxe6,
    .sprite-TwoHandAxe5,
    .sprite-TwoHandAxe4,
    .sprite-TwoHandAxe3,
    .sprite-TwoHandAxe2,
    .sprite-TwoHandAxe1,
    .sprite-Staff8,
    .sprite-Staff7,
    .sprite-Staff6,
    .sprite-Staff5,
    .sprite-Staff4,
    .sprite-Staff3,
    .sprite-Staff2,
    .sprite-Staff1,
    .sprite-ShieldStr7,
    .sprite-ShieldStr6,
    .sprite-ShieldStr5,
    .sprite-ShieldStr4,
    .sprite-ShieldStr3,
    .sprite-ShieldStr2,
    .sprite-ShieldStr1,
    .sprite-Rapier9,
    .sprite-Rapier8,
    .sprite-Rapier7,
    .sprite-Rapier6,
    .sprite-Rapier5,
    .sprite-Rapier4,
    .sprite-Rapier3,
    .sprite-Rapier2,
    .sprite-Rapier1,
    .sprite-OneHandSword3,
    .sprite-OneHandSword2,
    .sprite-OneHandSword1,
    .sprite-OneHandMace3,
    .sprite-OneHandMace2,
    .sprite-OneHandMace1,
    .sprite-OneHandAxe1,
    .sprite-Dagger9,
    .sprite-Dagger8,
    .sprite-Dagger7,
    .sprite-Dagger6,
    .sprite-Dagger5,
    .sprite-Dagger4,
    .sprite-Dagger3,
    .sprite-Dagger2,
    .sprite-Dagger1,
    .sprite-ConvokingWand,
    .sprite-Bow9,
    .sprite-Bow8,
    .sprite-Bow7,
    .sprite-Bow6,
    .sprite-Bow5,
    .sprite-Bow4,
    .sprite-Bow3,
    .sprite-scepter9,
    .sprite-scepter8,
    .sprite-scepter7,
    .sprite-scepter6,
    .sprite-scepter5,
    .sprite-scepter4,
    .sprite-scepter3,
    .sprite-scepter2,
    .sprite-TriggerQuiverUnique,
    .sprite-TheGoddessUnleashed,
    .sprite-ShieldStrInt5,
    .sprite-ShieldStrInt4,
    .sprite-ShieldStrInt3,
    .sprite-ShieldStrInt2,
    .sprite-ShieldStrInt1,
    .sprite-ShieldStrDex5,
    .sprite-ShieldStrDex4,
    .sprite-ShieldStrDex3,
    .sprite-ShieldStrDex2,
    .sprite-ShieldStrDex1,
    .sprite-SacrificialGarb,
    .sprite-QuiverTwo-Point,
    .sprite-QuiverSpike-Point,
    .sprite-QuiverSharktooth,
    .sprite-QuiverSerrated,
    .sprite-QuiverPenetrating,
    .sprite-QuiverFire,
    .sprite-QuiverBroadhead,
    .sprite-QuiverBlunt,
    .sprite-Quiver5,
    .sprite-Quiver4,
    .sprite-Quiver3,
    .sprite-Quiver2,
    .sprite-Quiver1,
    .sprite-OneHandSword9,
    .sprite-OneHandSword8,
    .sprite-OneHandSword7,
    .sprite-OneHandSword6,
    .sprite-OneHandSword5,
    .sprite-OneHandSword4,
    .sprite-OneHandMace9,
    .sprite-OneHandMace8,
    .sprite-OneHandMace7,
    .sprite-OneHandMace6,
    .sprite-OneHandMace5,
    .sprite-OneHandMace4,
    .sprite-OneHandAxe9,
    .sprite-OneHandAxe8,
    .sprite-OneHandAxe7,
    .sprite-OneHandAxe6,
    .sprite-OneHandAxe5,
    .sprite-OneHandAxe4,
    .sprite-OneHandAxe3,
    .sprite-OneHandAxe2,
    .sprite-DemigodsShield,
    .sprite-DemigodsArmour,
    .sprite-Bow2,
    .sprite-Bow1,
    .sprite-BodyStrInt3C,
    .sprite-BodyStrInt2C,
    .sprite-BodyStrInt2B,
    .sprite-BodyStrInt2A,
    .sprite-BodyStrInt1C,
    .sprite-BodyStrInt1B,
    .sprite-BodyStrInt1A,
    .sprite-BodyStrDexInt1C,
    .sprite-BodyStrDex2C,
    .sprite-BodyStrDex2B,
    .sprite-BodyStrDex2A,
    .sprite-BodyStrDex1C,
    .sprite-BodyStrDex1B,
    .sprite-BodyStrDex1A,
    .sprite-BodyStr4A,
    .sprite-BodyStr3CUnique,
    .sprite-BodyStr3C,
    .sprite-BodyStr2C,
    .sprite-BodyStr2B,
    .sprite-BodyStr2A,
    .sprite-BodyStr1C,
    .sprite-BodyStr1B,
    .sprite-BodyStr1A,
    .sprite-BodyInt2C,
    .sprite-BodyInt2B,
    .sprite-BodyInt2A,
    .sprite-BodyInt1C,
    .sprite-BodyInt1B,
    .sprite-BodyInt1A,
    .sprite-BodyDexInt4C,
    .sprite-BodyDexInt2C,
    .sprite-BodyDexInt2B,
    .sprite-BodyDexInt2A,
    .sprite-BodyDexInt1C,
    .sprite-BodyDexInt1B,
    .sprite-BodyDexInt1A,
    .sprite-BodyDex3C,
    .sprite-BodyDex3B,
    .sprite-BodyDex3A,
    .sprite-BodyDex2C,
    .sprite-BodyDex1C,
    .sprite-BodyDex1B,
    .sprite-BodyDex1A,
    .sprite-basicstr,
    .sprite-basicint,
    .sprite-basicdex,
    .sprite-Zeal7,
    .sprite-Zeal6,
    .sprite-Zeal5,
    .sprite-Zeal4,
    .sprite-Wrath7,
    .sprite-Wrath6,
    .sprite-Wrath5,
    .sprite-Wrath4,
    .sprite-Wrath3,
    .sprite-Woe7,
    .sprite-Woe6,
    .sprite-Woe5,
    .sprite-Woe4,
    .sprite-Woe3,
    .sprite-Woe2,
    .sprite-Woe1,
    .sprite-VioletOil,
    .sprite-VialTemperedFlesh,
    .sprite-VialStoryVaal,
    .sprite-VialSpiritDrinker,
    .sprite-VialSoulCatcher,
    .sprite-VialSlumber,
    .sprite-VialSacrificialHeart,
    .sprite-VialDanceOffered,
    .sprite-VialCowardsChains,
    .sprite-VialArchitectsHand,
    .sprite-VermillionRing,
    .sprite-VerdantOil,
    .sprite-VaalShard,
    .sprite-TwoTonedBoots3B,
    .sprite-TwoTonedBoots2B,
    .sprite-TwoTonedBoots,
    .sprite-TurquoiseAmulet,
    .sprite-TurbulentCatalyst,
    .sprite-TransferOrb,
    .sprite-Torment7,
    .sprite-Torment6,
    .sprite-Torment5,
    .sprite-Torment4,
    .sprite-Torment3,
    .sprite-Torment2,
    .sprite-TopazSapphire,
    .sprite-TopazRuby,
    .sprite-Timeless,
    .sprite-Terror1,
    .sprite-TemplarShard,
    .sprite-TemperingCatalyst,
    .sprite-TealOil,
    .sprite-TangledFossil,
    .sprite-TalismanoftheVictor,
    .sprite-TalismanWhite8,
    .sprite-TalismanWhite7,
    .sprite-TalismanWhite6,
    .sprite-TalismanWhite5,
    .sprite-TalismanWhite4,
    .sprite-TalismanWhite3,
    .sprite-TalismanWhite2,
    .sprite-TalismanWhite1,
    .sprite-TalismanRed8,
    .sprite-TalismanRed7,
    .sprite-TalismanRed6,
    .sprite-TalismanRed4,
    .sprite-TalismanRed3,
    .sprite-TalismanRed1,
    .sprite-TalismanBlack7,
    .sprite-TalismanBlack6,
    .sprite-TalismanBlack5,
    .sprite-TalismanBlack4,
    .sprite-TalismanBlack3,
    .sprite-TalismanBlack2,
    .sprite-T4Talisman,
    .sprite-Suffering7,
    .sprite-Suffering6,
    .sprite-Suffering5,
    .sprite-Suffering4,
    .sprite-Suffering3,
    .sprite-Spite7,
    .sprite-Spite6,
    .sprite-Spite5,
    .sprite-Spite4,
    .sprite-SpikedGloves,
    .sprite-Sorrow7,
    .sprite-Sorrow6,
    .sprite-Sorrow5,
    .sprite-Sorrow4,
    .sprite-Sorrow3,
    .sprite-Sorrow2,
    .sprite-SilverOil,
    .sprite-SilverObol,
    .sprite-ShudderingFossil,
    .sprite-ShieldInt6,
    .sprite-ShieldInt5,
    .sprite-ShieldInt4,
    .sprite-ShieldInt3,
    .sprite-ShieldInt2,
    .sprite-ShieldInt1,
    .sprite-ShieldDexInt5,
    .sprite-ShieldDexInt4,
    .sprite-ShieldDexInt3,
    .sprite-ShieldDexInt2,
    .sprite-ShieldDexInt1,
    .sprite-ShieldDex6,
    .sprite-ShieldDex5,
    .sprite-ShieldDex4,
    .sprite-ShieldDex3,
    .sprite-ShieldDex2,
    .sprite-ShieldDex1,
    .sprite-SerratedFossil,
    .sprite-SepiaOil,
    .sprite-SearchingEye,
    .sprite-SealYellow,
    .sprite-SealWhite,
    .sprite-SealRed,
    .sprite-Scorn7,
    .sprite-Scorn6,
    .sprite-Scorn5,
    .sprite-SapphireRuby,
    .sprite-SanctifiedFossil,
    .sprite-RubyAmulet,
    .sprite-RivetedEye,
    .sprite-RingoftheVictor,
    .sprite-Ring7,
    .sprite-Ring6,
    .sprite-Ring5,
    .sprite-Ring4,
    .sprite-Ring3,
    .sprite-Ring2,
    .sprite-Ring12,
    .sprite-Ring1,
    .sprite-ResonatingFossil,
    .sprite-RegalShard,
    .sprite-Rage7,
    .sprite-Rage6,
    .sprite-Rage5,
    .sprite-Rage4,
    .sprite-Rage3,
    .sprite-ProphecyOrbRed,
    .sprite-PristineFossil,
    .sprite-PrismaticFossil,
    .sprite-PrismaticCatalyst,
    .sprite-PerfectFossil,
    .sprite-OpalescentOil,
    .sprite-OpalRing,
    .sprite-NewTalisman7,
    .sprite-NewTalisman3,
    .sprite-NewTalisman2,
    .sprite-NewTalisman,
    .sprite-NewGemBase3,
    .sprite-NewGemBase2,
    .sprite-NewGemBase1,
    .sprite-Necronet,
    .sprite-MurderousEye,
    .sprite-MoonstoneRing,
    .sprite-MonkeyPawTalisman,
    .sprite-Misery7,
    .sprite-Misery6,
    .sprite-Misery5,
    .sprite-MirrorShard,
    .sprite-MetallicFossil,
    .sprite-Mastery,
    .sprite-MarbleAmulet,
    .sprite-MarakethShard,
    .sprite-Madness1,
    .sprite-LucentFossil,
    .sprite-Loathing7,
    .sprite-Loathing6,
    .sprite-Loathing5,
    .sprite-Loathing4,
    .sprite-KaruiShard,
    .sprite-JaggedFossil,
    .sprite-IntrinsicCatalyst,
    .sprite-Insanity1,
    .sprite-IncursionItemCorruption,
    .sprite-IncursionGemCorruption,
    .sprite-ImbuedCatalyst,
    .sprite-Horror1,
    .sprite-HorizonShard,
    .sprite-HorizonOrb,
    .sprite-HelmetStrInt9,
    .sprite-HelmetStrInt8,
    .sprite-HelmetStrInt7,
    .sprite-HelmetStrInt6,
    .sprite-HelmetStrInt5,
    .sprite-HelmetStrInt4,
    .sprite-HelmetStrInt3,
    .sprite-HelmetStrInt2,
    .sprite-HelmetStrInt10,
    .sprite-HelmetStrInt1,
    .sprite-HelmetStrDex9,
    .sprite-HelmetStrDex8,
    .sprite-HelmetStrDex7,
    .sprite-HelmetStrDex6,
    .sprite-HelmetStrDex5,
    .sprite-HelmetStrDex4,
    .sprite-HelmetStrDex3,
    .sprite-HelmetStrDex2,
    .sprite-HelmetStrDex10,
    .sprite-HelmetStrDex1,
    .sprite-HelmetStr9,
    .sprite-HelmetStr8,
    .sprite-HelmetStr7,
    .sprite-HelmetStr6,
    .sprite-HelmetStr5,
    .sprite-HelmetStr4,
    .sprite-HelmetStr3,
    .sprite-HelmetStr2,
    .sprite-HelmetStr11,
    .sprite-HelmetStr10,
    .sprite-HelmetStr1,
    .sprite-HelmetInt9,
    .sprite-HelmetInt8,
    .sprite-HelmetInt7,
    .sprite-HelmetInt6,
    .sprite-HelmetInt5,
    .sprite-HelmetInt4,
    .sprite-HelmetInt3,
    .sprite-HelmetInt2,
    .sprite-HelmetInt11,
    .sprite-HelmetInt10,
    .sprite-HelmetInt1,
    .sprite-HelmetDexInt9,
    .sprite-HelmetDexInt8,
    .sprite-HelmetDexInt7,
    .sprite-HelmetDexInt6,
    .sprite-HelmetDexInt5,
    .sprite-HelmetDexInt4,
    .sprite-HelmetDexInt3,
    .sprite-HelmetDexInt2,
    .sprite-HelmetDexInt11,
    .sprite-HelmetDexInt10,
    .sprite-HelmetDexInt1,
    .sprite-HelmetDex9,
    .sprite-HelmetDex8,
    .sprite-HelmetDex7,
    .sprite-HelmetDex6,
    .sprite-HelmetDex5,
    .sprite-HelmetDex4,
    .sprite-HelmetDex3,
    .sprite-HelmetDex2,
    .sprite-HelmetDex10,
    .sprite-HelmetDex1,
    .sprite-Hatred7,
    .sprite-Hatred6,
    .sprite-Hatred5,
    .sprite-Hatred4,
    .sprite-Hatred3,
    .sprite-Hatred2,
    .sprite-Hatred1,
    .sprite-HarbingerShard,
    .sprite-HarbingerOrb,
    .sprite-GripGlovesBaseType,
    .sprite-Greed7,
    .sprite-Greed6,
    .sprite-Greed5,
    .sprite-Greed4,
    .sprite-Greed3,
    .sprite-Greed2,
    .sprite-Greed1,
    .sprite-GoldenOil,
    .sprite-GlyphicFossil,
    .sprite-GlovesStrInt3,
    .sprite-GlovesStrInt2,
    .sprite-GlovesStrInt1,
    .sprite-GlovesStrDex3,
    .sprite-GlovesStrDex2,
    .sprite-GlovesStrDex1,
    .sprite-GlovesStr4,
    .sprite-GlovesStr3,
    .sprite-GlovesStr2,
    .sprite-GlovesStr1,
    .sprite-GlovesInt4,
    .sprite-GlovesInt3,
    .sprite-GlovesInt2,
    .sprite-GlovesInt1,
    .sprite-GlovesDexInt3,
    .sprite-GlovesDexInt2,
    .sprite-GlovesDexInt1,
    .sprite-GlovesDex4,
    .sprite-GlovesDex3,
    .sprite-GlovesDex2,
    .sprite-GlovesDex1,
    .sprite-GildedFossil,
    .sprite-GhastlyEye,
    .sprite-FracturedFossil,
    .sprite-FishTalisman,
    .sprite-FingerlessSilkGloves,
    .sprite-FertileCatalyst,
    .sprite-Fear7,
    .sprite-Fear6,
    .sprite-Fear5,
    .sprite-Fear4,
    .sprite-Fear3,
    .sprite-Fear2,
    .sprite-FacetedFossil,
    .sprite-ExaltedShard,
    .sprite-EternalEmpireShard,
    .sprite-EssenceCorrupt,
    .sprite-Envy7,
    .sprite-Envy6,
    .sprite-Envy5,
    .sprite-EngineersShard,
    .sprite-EngineersOrb,
    .sprite-EncrustedFossil,
    .sprite-Enchantment,
    .sprite-EnchantedFossil,
    .sprite-Empty-Socket,
    .sprite-Dread7,
    .sprite-Dread6,
    .sprite-Dread5,
    .sprite-Doubt7,
    .sprite-Doubt6,
    .sprite-Doubt5,
    .sprite-Doubt4,
    .sprite-Doubt3,
    .sprite-DireClawTalisman,
    .sprite-DiamondRing,
    .sprite-DenseFossil,
    .sprite-Demigodstriumph,
    .sprite-DemigodsTouch,
    .sprite-DemigodsStride,
    .sprite-DemigodsImmortality,
    .sprite-DemigodsBand,
    .sprite-Demibelt1,
    .sprite-DeliriumSplinter,
    .sprite-DeliriumOrbWeapons,
    .sprite-DeliriumOrbUniques,
    .sprite-DeliriumOrbTrinkets,
    .sprite-DeliriumOrbTalismans,
    .sprite-DeliriumOrbScarabs,
    .sprite-DeliriumOrbProphecies,
    .sprite-DeliriumOrbPerandus,
    .sprite-DeliriumOrbMetamorph,
    .sprite-DeliriumOrbMaps,
    .sprite-DeliriumOrbLegion,
    .sprite-DeliriumOrbLabyrinth,
    .sprite-DeliriumOrbHarbinger,
    .sprite-DeliriumOrbGeneric,
    .sprite-DeliriumOrbGems,
    .sprite-DeliriumOrbFragments,
    .sprite-DeliriumOrbFossils,
    .sprite-DeliriumOrbEssence,
    .sprite-DeliriumOrbDivinationCards,
    .sprite-DeliriumOrbCurrency,
    .sprite-DeliriumOrbBreach,
    .sprite-DeliriumOrbBlight,
    .sprite-DeliriumOrbArmour,
    .sprite-DeliriumOrbAbyss,
    .sprite-Deck,
    .sprite-CurrencyWeaponQuality,
    .sprite-CurrencyVaal,
    .sprite-CurrencyUpgradeToRareShard,
    .sprite-CurrencyUpgradeToRare,
    .sprite-CurrencyUpgradeToMagicShard,
    .sprite-CurrencyUpgradeToMagic,
    .sprite-CurrencyUpgradeRandomly,
    .sprite-CurrencyUpgradeMagicToRare,
    .sprite-CurrencyRhoaFeather,
    .sprite-CurrencyRerollSocketNumbers,
    .sprite-CurrencyRerollSocketLinks,
    .sprite-CurrencyRerollSocketColours,
    .sprite-CurrencyRerollRare,
    .sprite-CurrencyRerollMagicShard,
    .sprite-CurrencyRerollMagic,
    .sprite-CurrencyPortal,
    .sprite-CurrencyPassiveSkillRefund,
    .sprite-CurrencyModValues,
    .sprite-CurrencyMapQuality,
    .sprite-CurrencyImprintOrb,
    .sprite-CurrencyImprint,
    .sprite-CurrencyImplicitMod,
    .sprite-CurrencyIdentificationFragment,
    .sprite-CurrencyIdentification,
    .sprite-CurrencyGemQuality,
    .sprite-CurrencyFlaskQuality,
    .sprite-CurrencyDuplicate,
    .sprite-CurrencyConvertToNormal,
    .sprite-CurrencyCoin,
    .sprite-CurrencyArmourQuality,
    .sprite-CurrencyAddModToRare,
    .sprite-CurrencyAddModToMagic,
    .sprite-CrystalBelt,
    .sprite-CrimsonOil,
    .sprite-CorrodedFossil,
    .sprite-Contempt7,
    .sprite-Contempt6,
    .sprite-Contempt5,
    .sprite-Contempt4,
    .sprite-Contempt3,
    .sprite-Contempt2,
    .sprite-Contempt1,
    .sprite-ClearOil,
    .sprite-Claw9,
    .sprite-Claw8,
    .sprite-Claw7,
    .sprite-Claw6,
    .sprite-Claw5,
    .sprite-Claw4,
    .sprite-Claw3,
    .sprite-Claw2,
    .sprite-Claw1,
    .sprite-CitrineAmulet,
    .sprite-ChaosShard,
    .sprite-CeruleanRing,
    .sprite-BreachUpgraderPhysical,
    .sprite-BreachUpgraderLightning,
    .sprite-BreachUpgraderFire,
    .sprite-BreachUpgraderCold,
    .sprite-BreachUpgraderChaos,
    .sprite-BreachShardPhysical,
    .sprite-BreachShardLightning,
    .sprite-BreachShardFire,
    .sprite-BreachShardCold,
    .sprite-BreachShardChaos,
    .sprite-BreachRing,
    .sprite-BootsStrInt3,
    .sprite-BootsStrInt2,
    .sprite-BootsStrInt1,
    .sprite-BootsStrDex3,
    .sprite-BootsStrDex2,
    .sprite-BootsStrDex1,
    .sprite-BootsStr4,
    .sprite-BootsStr3,
    .sprite-BootsStr2,
    .sprite-BootsStr1,
    .sprite-BootsInt4,
    .sprite-BootsInt3,
    .sprite-BootsInt2,
    .sprite-BootsInt1,
    .sprite-BootsDexInt4,
    .sprite-BootsDexInt3,
    .sprite-BootsDexInt2,
    .sprite-BootsDexInt1,
    .sprite-BootsDex4,
    .sprite-BootsDex3,
    .sprite-BootsDex2,
    .sprite-BootsDex1,
    .sprite-BoneHelm,
    .sprite-BluePearlAmulet,
    .sprite-BloodstainedFossil,
    .sprite-BlackOil,
    .sprite-BindingShard,
    .sprite-BindingOrb,
    .sprite-BestiaryTrap9,
    .sprite-BestiaryTrap8,
    .sprite-BestiaryTrap7,
    .sprite-BestiaryTrap6,
    .sprite-BestiaryTrap5,
    .sprite-BestiaryTrap4,
    .sprite-BestiaryTrap3,
    .sprite-BestiaryTrap2,
    .sprite-BestiaryTrap10,
    .sprite-BestiaryTrap1,
    .sprite-BestiaryOrbFull,
    .sprite-BestiaryOrbEmpty,
    .sprite-Belt7,
    .sprite-Belt6,
    .sprite-Belt5,
    .sprite-Belt4,
    .sprite-Belt3,
    .sprite-Belt2,
    .sprite-Belt1,
    .sprite-AzureOil,
    .sprite-AtlasRadiusYellow,
    .sprite-AtlasRadiusWhite,
    .sprite-AtlasRadiusTier3,
    .sprite-AtlasRadiusTier2,
    .sprite-AtlasRadiusTier1,
    .sprite-AtlasRadiusRed,
    .sprite-AtlasDowngrade,
    .sprite-AnnullShard,
    .sprite-AnnullOrb,
    .sprite-Anguish7,
    .sprite-Anguish6,
    .sprite-Anguish5,
    .sprite-Anguish4,
    .sprite-Anger7,
    .sprite-Anger6,
    .sprite-Anger5,
    .sprite-Anger4,
    .sprite-Anger3,
    .sprite-Anger2,
    .sprite-AncientShard,
    .sprite-AncientOrb,
    .sprite-Amulet7,
    .sprite-Amulet6,
    .sprite-Amulet5,
    .sprite-Amulet4,
    .sprite-Amulet3,
    .sprite-Amulet2,
    .sprite-Amulet1,
    .sprite-AmethystRing,
    .sprite-AmberOil,
    .sprite-AmberFireFly,
    .sprite-AmberFang,
    .sprite-AmberEye,
    .sprite-AgateAmulet,
    .sprite-AethericFossil,
    .sprite-AdamantineRing,
    .sprite-AbyssBelt,
    .sprite-AbrasiveCatalyst,
    .sprite-AbberantFossil {
                background-image: url('poe-sprite-small.png');
                -webkit-background-size: 840px 840px;
                -moz-background-size: 840px 840px;
                background-size: 840px 840px;
            }
        }
        