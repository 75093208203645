@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/webfonts/fa-solid-900.eot?v=5.8.2");
    src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/webfonts/fa-solid-900.eot?#iefix&v=5.8.2") format("embedded-opentype"),
        url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/webfonts/fa-solid-900.woff2?v=5.8.2") format("woff2"),
        url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/webfonts/fa-solid-900.woff?v=5.8.2") format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/webfonts/fa-solid-900.ttf?v=5.8.2") format("truetype"),
        url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/webfonts/fa-solid-900.svg?v=5.8.2#FontAwesome5Free-Solid") format("svg");
}